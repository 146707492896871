import gql from 'graphql-tag';

export const MissedCommentEventsQuery = gql`
	query MissedCommentEventsQuery($contentId: Long!, $since: Long!) {
		quickReload(pageId: $contentId, since: $since) {
			time
			comments {
				comment {
					id
					location {
						type
					}
					author {
						... on KnownUser {
							accountId
						}
						... on UnknownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
			}
		}
	}
`;
