import gql from 'graphql-tag';

export const InlineCommentQueryParamsQuery = gql`
	query InlineCommentQueryParamsQuery($commentId: ID!) {
		comments(commentId: $commentId) {
			nodes {
				id
				location {
					type
					... on InlineComment {
						inlineMarkerRef
						inlineResolveProperties {
							resolved
						}
					}
				}
				ancestors {
					id
					location {
						type
						... on InlineComment {
							inlineMarkerRef
							inlineResolveProperties {
								resolved
							}
						}
					}
				}
				parentId
			}
		}
	}
`;
