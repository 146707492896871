import { useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';

import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { useInlineCommentQueryParams } from '@confluence/comment';
import {
	checkElementExpandInEditor,
	scrollToElementWithViewingRoom,
} from '@confluence/comments-util';
import { getLogger } from '@confluence/logger';
import { markErrorAsHandled } from '@confluence/graphql';

import { InlineCommentQueryParamsQuery } from './graphql/InlineCommentQueryParamsQuery.graphql';
import type {
	InlineCommentQueryParamsQuery as InlineCommentQueryParamsQueryData,
	InlineCommentQueryParamsQueryVariables,
	InlineCommentQueryParamsQuery_comments_nodes_location_InlineComment as InlineCommentLocation,
} from './graphql/__types__/InlineCommentQueryParamsQuery';

const logger = getLogger('inline-comments:query-param-listener');

type InlineCommentQueryParamsListenerProps = {
	isArchived: boolean;
};

export const InlineCommentQueryParamsListener: React.FC<InlineCommentQueryParamsListenerProps> = ({
	isArchived,
}) => {
	const eventSentRef = useRef<string | undefined>(undefined);
	const { focusedCommentId, replyToCommentId, editCommentId } = useInlineCommentQueryParams({
		queryArchivedContent: isArchived,
	});

	const commentId = focusedCommentId || replyToCommentId || editCommentId;

	const { data, loading, error } = useQuery<
		InlineCommentQueryParamsQueryData,
		InlineCommentQueryParamsQueryVariables
	>(InlineCommentQueryParamsQuery, {
		variables: {
			commentId: commentId!,
		},
		skip: !commentId,
	});

	if (error) {
		logger.error`An error occurred when querying the focused comment status; ${error}`;

		// TODO: If this query fails, there isn't much we can do, so just track it with experience tracker?

		markErrorAsHandled(error);
	} else if (commentId && !loading && data && eventSentRef.current !== commentId) {
		const location = data.comments?.nodes?.[0]?.location as InlineCommentLocation | undefined;
		const isReply = data.comments?.nodes?.[0]?.parentId;

		if (location) {
			let annotationId = location.inlineMarkerRef;
			let isResolved = Boolean(location.inlineResolveProperties?.resolved);

			if (!annotationId) {
				// This is a child comment, find the parent and use its markerRef and resolved state
				const parentLocation = data.comments?.nodes?.[0]?.ancestors?.[0]?.location as
					| InlineCommentLocation
					| undefined;

				if (parentLocation) {
					annotationId = parentLocation.inlineMarkerRef;
					isResolved = Boolean(parentLocation.inlineResolveProperties?.resolved);
				}
			}

			if (!isResolved) {
				const mark = document.querySelector(`[data-id="${annotationId}"]`) as HTMLElement | null;

				if (mark) {
					eventSentRef.current = commentId;
					let focusedMarkerRef = document.querySelector("[data-has-focus='true']");

					// If the already open container is showing the correct comment, don't send another click event to open it
					if (focusedMarkerRef?.id !== annotationId) {
						const emitter = getRendererAnnotationEventEmitter();

						setTimeout(() => {
							checkElementExpandInEditor(mark);
							// Excerpt(nested renderer) like macros re-render this component making the
							// highlight to be removed from the DOM
							// so, need to check the focus attribute once the ON_ANNOTATION_CLICK gets triggered
							focusedMarkerRef = document.querySelector("[data-has-focus='true']");

							if (
								!focusedMarkerRef &&
								emitter.emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
									annotationIds: [annotationId],
									eventTarget: mark,
								})
							) {
								emitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
									annotationId,
								});
							}
							// We need the timeout to give the document the chance to make the marks properly
						}, 1000);
					}
				}

				// if the comment is a reply, don't scroll to it here, we'll handle the reply scrolling after the reply has rendered to avoid double scrolling
				if (!isReply && mark) {
					// note that this is for Renderer for now
					scrollToElementWithViewingRoom({
						element: mark,
						useInstantScroll: true,
					});
				}
			}
		}
	}

	return null;
};
