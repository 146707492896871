import React, { memo, useEffect, useCallback, useState, useRef } from 'react';
import type { FC } from 'react';

import type { InlineCommentViewComponentProps } from '@atlaskit/editor-common/types';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationTypes } from '@atlaskit/adf-schema';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import type { AnnotationInfo } from '@atlaskit/editor-plugins/annotation';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';

import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { useDocumentUpdateStatus } from '@confluence/annotation-provider-store';
import { useInlineCommentQueryParams } from '@confluence/comment';
import {
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { fg } from '@confluence/feature-gating';
import { useCommentsPanel, scrollCommentsPanel } from '@confluence/comments-panel';

import { InlineComment } from '../InlineComment';

export type ViewComponentProps = {
	pageId: string;
	updateDocument?: (doc: JSONDocNode) => void;
	isArchived?: boolean;
	wasHighlightClicked?: boolean;
	pageContainsBodiedMacros?: boolean;
};

export const rendererHandleNavigationClick = async (nextMarkerRef: string) => {
	const emitter = getRendererAnnotationEventEmitter();
	nextMarkerRef && rendererUpdateEvent(emitter, nextMarkerRef);
};

export const rendererUpdateEvent = (
	eventEmitter: AnnotationUpdateEmitter,
	newSelection?: undefined | string,
) => {
	if (newSelection) {
		eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
			annotationId: newSelection,
		});
	} else {
		eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
		eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
	}
};

export const ViewComponent: FC<InlineCommentViewComponentProps & ViewComponentProps> = memo(
	({
		annotations: clickedAnnotations,
		getInlineNodeTypes,
		deleteAnnotation,
		pageId,
		updateDocument,
		isArchived,
		wasHighlightClicked,
		pageContainsBodiedMacros,
	}) => {
		const { documentUpdated } = useDocumentUpdateStatus();
		const [selectedAnnotation, setSelectedAnnotation] = useState<AnnotationInfo | null | undefined>(
			null,
		);
		const [eventEmitter] = useState(getRendererAnnotationEventEmitter());
		const prevPageIdRef = useRef(pageId);
		const { removeCommentQueryParams } = useInlineCommentQueryParams();
		const { hasContentChanged } = useCommentContentContext();
		const { resetContentChanged } = useCommentContentDispatchContext();
		const { showModal } = useDialogs();
		const [{ isCommentsPanelOpen }, { setCurrentlySelectedCommentMarkerRef }] = useCommentsPanel();

		const supportedActions: CommentAction[] = pageContainsBodiedMacros
			? ['edit', 'resolve']
			: ['edit', 'resolve', 'delete'];

		const handleAnnotationClick = useCallback(
			(annotation?: AnnotationInfo) => {
				// If the current annotation has been clicked again
				if (selectedAnnotation && annotation && selectedAnnotation.id === annotation.id) {
					// Send an event to tell the Renderer to de-select the annotation
					rendererUpdateEvent(eventEmitter);
					setSelectedAnnotation(null);

					if (isCommentsPanelOpen && fg('confluence-frontend-comments-panel')) {
						setCurrentlySelectedCommentMarkerRef('');
					}
				} else {
					// Send an event to tell the renderer to select the annotation
					rendererUpdateEvent(eventEmitter, annotation?.id);
					// Select it in our code
					setSelectedAnnotation(annotation);

					const shouldSetSelectedCommentMarkerRef = isCommentsPanelOpen && annotation;
					if (shouldSetSelectedCommentMarkerRef && fg('confluence-frontend-comments-panel')) {
						setCurrentlySelectedCommentMarkerRef(annotation.id);
						scrollCommentsPanel({
							containerId: 'comments-panel-list-container',
							commentMarkerRef: `comment-thread-${annotation.id}-container`,
						});
					}
				}
			},
			[
				eventEmitter,
				selectedAnnotation,
				setSelectedAnnotation,
				isCommentsPanelOpen,
				setCurrentlySelectedCommentMarkerRef,
			],
		);

		const onNavigationClick = useCallback(
			(nextMarkerRef: string) => {
				const doNavigation = async () => {
					await rendererHandleNavigationClick(nextMarkerRef);
					handleAnnotationClick({
						id: nextMarkerRef,
						type: AnnotationTypes.INLINE_COMMENT,
					});
				};

				if (hasContentChanged) {
					showModal(CommentWarningDialog, {
						onConfirm: () => {
							resetContentChanged();
							void doNavigation();
						},
					});
				} else {
					void doNavigation();
				}
			},
			[hasContentChanged, handleAnnotationClick, resetContentChanged, showModal],
		);

		useEffect(() => {
			if (!clickedAnnotations.length && !selectedAnnotation) {
				setSelectedAnnotation(null);
				eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
				return;
			}

			// Multi-selection to be implemented in future
			const newlySelectedAnnotation = clickedAnnotations[0];

			if (documentUpdated && !wasHighlightClicked) {
				if (selectedAnnotation?.id === newlySelectedAnnotation?.id) {
					return;
				}
			}

			handleAnnotationClick(newlySelectedAnnotation);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [clickedAnnotations]);

		useEffect(() => {
			const prevPageId = prevPageIdRef.current;

			// Whenever the pageId changes, we need to close anything that's open
			if (pageId !== prevPageId) {
				handleOnClose();
			}

			return () => {
				if (pageId !== prevPageId) {
					handleOnClose();
				}
			};
			// We only want to listen to the pageId changing
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [pageId]);

		const handleOnClose = useCallback(() => {
			// Inform the Renderer to de-select the annotation
			eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
			eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
			// Remove the display of the comment
			setSelectedAnnotation(null);
			removeCommentQueryParams();
		}, [eventEmitter, setSelectedAnnotation, removeCommentQueryParams]);

		const onDeleteSuccess = (doc: JSONDocNode) => {
			if (doc) {
				// Inform the Renderer to de-select the annotation
				eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
				eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
				updateDocument && updateDocument(doc);
			}
		};

		const shouldRenderStandaloneInlineComment =
			selectedAnnotation && (!fg('confluence-frontend-comments-panel') || !isCommentsPanelOpen);
		return shouldRenderStandaloneInlineComment ? (
			<InlineComment
				annotation={selectedAnnotation}
				getInlineNodeTypes={getInlineNodeTypes}
				isArchived={isArchived}
				onClose={handleOnClose}
				onResolve={() => {}}
				deleteOptions={{
					deleteAnnotation,
					onDeleteSuccess,
				}}
				onNavigationClick={onNavigationClick}
				supportedTopLevelActions={supportedActions}
			/>
		) : null;
	},
);
