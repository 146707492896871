import { type FC, memo, useEffect } from 'react';

import type { RendererActions } from '@atlaskit/renderer/actions';

import { useRendererActionsDispatch } from './RendererActionsContext';

type RendererActionsListenerProps = {
	actions: RendererActions;
};

export const RendererActionsListener: FC<RendererActionsListenerProps> = memo(({ actions }) => {
	const { setRendererActions } = useRendererActionsDispatch();

	useEffect(() => {
		setRendererActions(actions);
	}, [actions, setRendererActions]);

	return null;
});
