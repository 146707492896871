import React from 'react';

import type { SVGProps } from '@atlaskit/icon/svg';
import SVG from '@atlaskit/icon/svg';
import { token } from '@atlaskit/tokens';
import { B400, N500 } from '@atlaskit/theme/colors';

type AppIconProps = SVGProps & {
	isSelected?: boolean;
};

export const AppIcon = ({ size, label, isSelected }: AppIconProps) => (
	<SVG size={size} label={label}>
		<path
			d="M9 13C10.0544 13 10.9182 13.8158 10.9945 14.8507L11 15V18C11 19.0544 10.1841 19.9182 9.14926 19.9945L9 20H6C4.94564 20 4.08183 19.1842 4.00549 18.1493L4 18V15C4 13.9456 4.81588 13.0818 5.85074 13.0055L6 13H9ZM9 15H6V18H9V15ZM18 4C19.0544 4 19.9182 4.81585 19.9945 5.85071L20 6V9C20 10.0544 19.1841 10.9182 18.1493 10.9945L18 11H15C13.9456 11 13.0818 10.1842 13.0055 9.14929L13 9V6C13 4.94564 13.8159 4.08183 14.8507 4.00549L15 4H18ZM9 4C10.0544 4 10.9182 4.81585 10.9945 5.85071L11 6V9C11 10.0544 10.1841 10.9182 9.14926 10.9945L9 11H6C4.94564 11 4.08183 10.1842 4.00549 9.14929L4 9V6C4 4.94564 4.81588 4.08183 5.85074 4.00549L6 4H9ZM18 6H15V9H18V6ZM9 6H6V9H9V6Z"
			fill={isSelected ? token('color.icon.selected', B400) : token('color.icon', N500)}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.5 19C15.5 19.5523 15.9477 20 16.5 20C17.0523 20 17.5 19.5523 17.5 19V17.5H19C19.5523 17.5 20 17.0523 20 16.5C20 15.9477 19.5523 15.5 19 15.5H17.5V14C17.5 13.4477 17.0523 13 16.5 13C15.9477 13 15.5 13.4477 15.5 14V15.5H14C13.4477 15.5 13 15.9477 13 16.5C13 17.0523 13.4477 17.5 14 17.5H15.5V19Z"
			fill={isSelected ? token('color.icon.selected', B400) : token('color.icon', N500)}
		/>
	</SVG>
);
