import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { RendererActions } from '@atlaskit/renderer/actions';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationMarkStates, AnnotationTypes } from '@atlaskit/adf-schema';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import { getPubSubClient, eventToAvi } from '@confluence/pubsub-client';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { updateRendererAnnotations } from '@confluence/comments-util';

import { InlineCommentPubSubEvents, PagePubSubEvents } from '../CommentPubSubEvents';
import { refreshCommentThread } from '../utils/refreshCommentThread';

type InlineCommentRequestBody = {
	adfBodyContent?: string;
	commentId: string;
	inlineCommentType: 'TOP_LEVEL' | 'REPLY';
	markerRef: string;
	publishVersionNumber?: number;
	step?: {
		to: number;
		from: number;
		pos?: number;
		mark: {
			attrs: {
				annotationType: AnnotationTypes;
			};
		};
	};
};

type CommentPublicationEventParams = {
	contentId?: string;
	currentUserId?: string | null;
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
	rendererActions?: RendererActions;
	updateDocument?: (newAdf: JSONDocNode) => void;
	updateUnresolvedInlineComment?: (unresolvedInlineComments: string[]) => void;
	needsContentUpdate?: boolean;
	setContentNeedsUpdate?: (contentNeedsUpdate: boolean) => void;
	publishedDocumentVersion?: number;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
};

type PagePublicationEventParams = {
	currentUserId?: string | null;
	setContentNeedsUpdate?: (contentNeedsUpdate: boolean) => void;
	publishedDocumentVersion?: number;
};

export type RendererInlineCommentsPubSubFn = {
	contentId: string;
	currentUserId: string | null;
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
	rendererActions: RendererActions;
	updateDocument: (newAdf: JSONDocNode) => void;
	updateUnresolvedInlineComment: (unresolvedInlineComments: string[]) => void;
	setContentNeedsUpdate?: (contentNeedsUpdate: boolean) => void;
	needsContentUpdate: boolean;
	publishedDocumentVersion: number;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};

export type RendererInlineCommentsPubUnsubFn = {
	contentId: string;
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
};

const sendFailedPubsubEvent = ({
	createAnalyticsEvent,
	publishVersionNumber,
	publishedDocumentVersion,
}: {
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	publishVersionNumber?: number;
	publishedDocumentVersion?: number;
}) => {
	if (createAnalyticsEvent && publishVersionNumber && publishedDocumentVersion) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				source: 'rendererInlineCommentsPubSub',
				action: 'failed',
				actionSubject: 'createPubSubEvent',
				actionSubjectId: 'rendererInlineComments',
				attributes: {
					stepVersion: publishVersionNumber,
					documentVersion: publishedDocumentVersion,
				},
			},
		}).fire();
	}
};

const isEventCommentThreadOpen = (markerRef?: string) => {
	if (!markerRef) {
		return false;
	}

	const focusedMarkerRef = document.querySelector("[data-has-focus='true']");

	// Figure out if the reply's parent markerRef is open
	// HACK: Is there a way to do this without query selectors?
	return focusedMarkerRef && focusedMarkerRef.id === markerRef;
};

export const applyStepAndAddCommentToDoc = ({
	inlineCommentRequestBody,
	rendererActions,
	updateDocument,
	needsContentUpdate,
	setContentNeedsUpdate,
	publishedDocumentVersion,
	createAnalyticsEvent,
}: {
	inlineCommentRequestBody: InlineCommentRequestBody;
	rendererActions?: RendererActions;
	updateDocument?: (newAdf: JSONDocNode) => void;
	needsContentUpdate?: boolean;
	setContentNeedsUpdate?: (contentNeedsUpdate: boolean) => void;
	publishedDocumentVersion?: number;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
}) => {
	if (createAnalyticsEvent) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				source: 'rendererInlineCommentsPubSub',
				action: 'received',
				actionSubject: 'createPubSubEvent',
				actionSubjectId: 'rendererInlineComments',
			},
		}).fire();
	}

	const { markerRef, step, publishVersionNumber } = inlineCommentRequestBody;

	// If the users content is out of date or different, the steps from this pubsub event will be wrong
	// The new comment flag shown by VQR will be triggered to refetch the content
	if (step && publishVersionNumber === publishedDocumentVersion && !needsContentUpdate) {
		const {
			to,
			from,
			pos,
			mark: {
				attrs: { annotationType },
			},
		} = step;

		const position = pos !== undefined ? { to: pos, from: pos } : { to, from };
		const annotation = { annotationId: markerRef!, annotationType };
		const result = rendererActions?.applyAnnotation(position, annotation);
		if (result) {
			updateDocument && updateDocument(result.doc);
		}
	} else {
		if (!needsContentUpdate) {
			setContentNeedsUpdate && setContentNeedsUpdate(true);
		}

		// If we can't apply the step, send an event
		sendFailedPubsubEvent({
			createAnalyticsEvent,
			publishVersionNumber,
			publishedDocumentVersion,
		});
	}
};

const applyStepAndRemoveCommentFromDoc = ({
	inlineCommentRequestBody,
	rendererActions,
	updateDocument,
	eventEmitter,
}: {
	inlineCommentRequestBody: InlineCommentRequestBody;
	rendererActions?: RendererActions;
	updateDocument?: (newAdf: JSONDocNode) => void;
	eventEmitter?: AnnotationUpdateEmitter;
	needsContentUpdate?: boolean;
}) => {
	const { markerRef } = inlineCommentRequestBody;

	if (isEventCommentThreadOpen(markerRef)) {
		eventEmitter?.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
	}

	const deleteResult = rendererActions?.deleteAnnotation(markerRef, AnnotationTypes.INLINE_COMMENT);

	if (deleteResult) {
		updateDocument && updateDocument(deleteResult.doc);
	}
};

const refreshCommentThreadIfOpen = ({
	contentId,
	inlineCommentRequestBody,
}: {
	contentId?: string;
	inlineCommentRequestBody: InlineCommentRequestBody;
}) => {
	const { markerRef } = inlineCommentRequestBody;

	if (isEventCommentThreadOpen(markerRef) && contentId) {
		// If so, update it's cache entry
		void refreshCommentThread(contentId, markerRef!);
	}

	// Otherwise do nothing
};

let pagePublicationEventParams: PagePublicationEventParams = {};
const setPagePublicationEventParams = (params: PagePublicationEventParams) => {
	pagePublicationEventParams = params;
};
const onPagePublicationEvent = async (event: string, payload: any) => {
	const {
		pageUpdateRequestBody: { confVersion },
	} = payload;

	const { setContentNeedsUpdate, publishedDocumentVersion } = pagePublicationEventParams;

	if (
		(event === PagePubSubEvents.PagePublished || event === PagePubSubEvents.BlogPublished) &&
		publishedDocumentVersion !== confVersion
	) {
		// TODO: Eventually we want to read the values passed to us to see if this is positional breaking
		setContentNeedsUpdate && setContentNeedsUpdate(true);
	}
};

let commentPublicationEventParams: CommentPublicationEventParams = {};
const setCommentPublicationEventParams = (params: CommentPublicationEventParams) => {
	commentPublicationEventParams = params;
};
const onCommentPublicationEvent = async (event: string, payload: any) => {
	const eventEmitter: AnnotationUpdateEmitter = getRendererAnnotationEventEmitter();
	const { accountId } = payload;
	const inlineCommentRequestBody: InlineCommentRequestBody = payload.inlineCommentRequestBody;
	const { inlineCommentType, markerRef } = inlineCommentRequestBody;
	const {
		contentId,
		currentUserId,
		rendererActions,
		updateDocument,
		updateUnresolvedInlineComment,
		needsContentUpdate,
		setContentNeedsUpdate,
		publishedDocumentVersion,
		createAnalyticsEvent,
	} = commentPublicationEventParams;

	// Don't do anything when the current user performs an action
	if (accountId === currentUserId) {
		return;
	}

	switch (event) {
		case InlineCommentPubSubEvents.InlineCommentUnresolved:
			eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
				[markerRef!]: {
					id: markerRef,
					annotationType: AnnotationTypes.INLINE_COMMENT,
					state: AnnotationMarkStates.ACTIVE,
				},
			});
			break;
		case InlineCommentPubSubEvents.InlineCommentResolved:
			// If the thread is open, close it
			if (isEventCommentThreadOpen(markerRef)) {
				eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
			}

			eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
				[markerRef!]: {
					id: markerRef,
					annotationType: AnnotationTypes.INLINE_COMMENT,
					state: AnnotationMarkStates.RESOLVED,
				},
			});
			break;
		case InlineCommentPubSubEvents.RendererInlineCommentCreated:
			if (inlineCommentType === 'TOP_LEVEL') {
				applyStepAndAddCommentToDoc({
					inlineCommentRequestBody,
					rendererActions,
					updateDocument,
					needsContentUpdate,
					setContentNeedsUpdate,
					publishedDocumentVersion,
					createAnalyticsEvent,
				});
			} else if (inlineCommentType === 'REPLY') {
				refreshCommentThreadIfOpen({
					contentId,
					inlineCommentRequestBody,
				});
			}
			break;
		case InlineCommentPubSubEvents.EditorInlineCommentCreated:
			if (inlineCommentType === 'REPLY') {
				refreshCommentThreadIfOpen({
					contentId,
					inlineCommentRequestBody,
				});
			}
			break;
		case InlineCommentPubSubEvents.InlineCommentDeleted:
			if (inlineCommentType === 'TOP_LEVEL') {
				applyStepAndRemoveCommentFromDoc({
					inlineCommentRequestBody,
					rendererActions,
					updateDocument,
					eventEmitter,
				});
			} else {
				refreshCommentThreadIfOpen({
					contentId,
					inlineCommentRequestBody,
				});
			}
			break;
		case InlineCommentPubSubEvents.InlineCommentReattached:
			applyStepAndAddCommentToDoc({
				inlineCommentRequestBody,
				rendererActions,
				updateDocument,
				needsContentUpdate,
				setContentNeedsUpdate,
				publishedDocumentVersion,
				createAnalyticsEvent,
			});
			break;
		case InlineCommentPubSubEvents.InlineCommentUpdated:
			refreshCommentThreadIfOpen({
				contentId,
				inlineCommentRequestBody,
			});
			break;
		default:
			return;
	}

	// Always update if we've handled the event
	if (
		inlineCommentType !== 'REPLY' &&
		!needsContentUpdate &&
		event !== InlineCommentPubSubEvents.RendererInlineCommentCreated &&
		event !== InlineCommentPubSubEvents.InlineCommentReattached &&
		event !== InlineCommentPubSubEvents.InlineCommentDeleted
	) {
		if (rendererActions && contentId && updateUnresolvedInlineComment) {
			updateRendererAnnotations({
				rendererActions,
				contentId: contentId!,
				updateUnresolvedInlineComment,
			});
		}
	}
};

export function setUpRendererInlineCommentsPubSub({
	contentId,
	currentUserId,
	contentType,
	rendererActions,
	updateDocument,
	updateUnresolvedInlineComment,
	setContentNeedsUpdate,
	needsContentUpdate,
	publishedDocumentVersion,
	createAnalyticsEvent,
}: RendererInlineCommentsPubSubFn) {
	const pubSubResourceType = contentType || 'page';

	setCommentPublicationEventParams({
		contentId,
		currentUserId,
		rendererActions,
		updateDocument,
		updateUnresolvedInlineComment,
		needsContentUpdate,
		setContentNeedsUpdate,
		publishedDocumentVersion,
		createAnalyticsEvent,
	});
	setPagePublicationEventParams({
		currentUserId,
		setContentNeedsUpdate,
		publishedDocumentVersion,
	});

	void getPubSubClient().then((client) => {
		client
			.joinChannel(pubSubResourceType, contentId)
			.finally(() => {
				void client.subscribe(eventToAvi('resolved:inline_comment'), onCommentPublicationEvent);

				void client.subscribe(eventToAvi('unresolved:inline_comment'), onCommentPublicationEvent);

				void client.subscribe(
					eventToAvi('created:renderer_inline_comment'),
					onCommentPublicationEvent,
				);

				void client.subscribe(
					eventToAvi('created:editor_inline_comment'),
					onCommentPublicationEvent,
				);

				void client.subscribe(eventToAvi('deleted:inline_comment'), onCommentPublicationEvent);

				void client.subscribe(eventToAvi('reattach:inline_comment'), onCommentPublicationEvent);

				void client.subscribe(eventToAvi('updated:inline_comment'), onCommentPublicationEvent);

				void client.subscribe(eventToAvi('publish:page'), onPagePublicationEvent);

				void client.subscribe(eventToAvi('publish:blogpost'), onPagePublicationEvent);
			})
			.catch((error) => {
				throw new Error(`PubSub Web Client joinChannel ${JSON.stringify(error)}`);
			});
	});
}

export function removeRendererInlineCommentsPubSub({
	contentId,
	contentType,
}: RendererInlineCommentsPubUnsubFn) {
	const pubSubResourceType = contentType || 'page';

	void getPubSubClient().then((client) => {
		void client.leaveChannel(pubSubResourceType, contentId);
		void client.unsubscribe(eventToAvi('resolved:inline_comment'), onCommentPublicationEvent);

		void client.unsubscribe(eventToAvi('unresolved:inline_comment'), onCommentPublicationEvent);

		void client.unsubscribe(
			eventToAvi('created:renderer_inline_comment'),
			onCommentPublicationEvent,
		);

		void client.unsubscribe(eventToAvi('created:editor_inline_comment'), onCommentPublicationEvent);

		void client.unsubscribe(eventToAvi('deleted:inline_comment'), onCommentPublicationEvent);

		void client.unsubscribe(eventToAvi('reattach:inline_comment'), onCommentPublicationEvent);

		void client.unsubscribe(eventToAvi('updated:inline_comment'), onCommentPublicationEvent);

		void client.unsubscribe(eventToAvi('publish:page'), onPagePublicationEvent);

		void client.unsubscribe(eventToAvi('publish:blogpost'), onPagePublicationEvent);
	});
}
